/*
    C-TXT EXTENSION
    Extension du composant générique c-txt
    https://github.com/ita-design-system/c-txt.scss
    Ce fichier doit servir à étendre ou surcharger les fonctionnalités
    du composant c-txt selon les besoins du projet
*/
$briks-components-generic: (
    // Nom du composant, obligatoirement txt
    txt: ( 
        // Extension activée true ou false
        enabled: true, 
        // Responsive activée true ou false pour l'extension
        responsive: true, 
        // Valeurs par défaut de l'extension
        defaults: (),
        // Liste des modifieurs contenant chacun une liste de propriétés qui 
        // soit surchargent les propriétés par défaut
        // soit ajoutent des propriétés
        // soit les deux
        modifiers: ( 
            // FONT SIZE
            fs-15: (
                font-size: my-font-size(15)
            ),
            fs-14: (
                font-size: my-font-size(14)
            ),
            fs-13: (
                font-size: my-font-size(13)
            ),
            fs-12: (
                font-size: my-font-size(12)
            ),
            fs-11: (
                font-size: my-font-size(11)
            ),
            fs-10: (
                font-size: my-font-size(10)
            ),
            fs-9: (
                font-size: my-font-size(9)
            ),
            fs-8: (
                font-size: my-font-size(8)
            ),
            fs-7: (
                font-size: my-font-size(7)
            ),
            fs-6: (
                font-size: my-font-size(6)
            ),
            fs-5: (
                font-size: my-font-size(5)
            ),
            fs-4: (
                font-size: my-font-size(4)
            ),
            fs-3: (
                font-size: my-font-size(3)
            ),
            fs-2: (
                font-size: my-font-size(2)
            ),
            fs-1: (
                font-size: my-font-size(1)
            ),
            // FONT FAMILIES
            ff-lead-900: (
                font-family: my-font-family(lead-900),
                font-weight: 900
            ),
            ff-lead-700: (
                font-family: my-font-family(lead-700),
                font-weight: 700
            ),
            ff-lead-600: (
                font-family: my-font-family(lead-600),
                font-weight: 600
            ),
            ff-lead-500: (
                font-family: my-font-family(lead-500),
                font-weight: 500
            ),
            ff-lead-400: (
                font-family: my-font-family(lead-400),
                font-weight: 400
            ),
            ff-lead-300: (
                font-family: my-font-family(lead-300),
                font-weight: 300
            ),
            ff-icomoon: (
                font-family: 'icomoon'
            ),
            // LETTER SPACINGS
            lsp-1: (
                letter-spacing: -0.05em
            ),
            lsp-2: (
                letter-spacing: -0.02em
            ),
            lsp-3: (
                letter-spacing: -0.01em
            ),
            // LINE HEIGHT
            lh-10: (
                line-height: 0.8em
            ),
            lh-11: (
                line-height: 1.14em
            )
        )
    )
);